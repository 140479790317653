<template>
  <Top />
  <div class="main" v-loading="faceRecord.loading">
    <TransactionNavbar :title="lang.v.transactionDetails" :version="data" />
    <div class="user">
      <div class="user-left">
        <img src="~@/assets/img/Ellipse.png" alt="" class="avatar" />
        <span class="nickname">{{ lang.v.anonymousUser }}</span>
      </div>
      <div class="user-right">
        <div>{{ lang.v.deal }}{{ faceRecord.dealCount }}</div>
      </div>
    </div>
    <payment :type="faceRecord.type" />
    <points-detailed
      :type="faceRecord.type"
      :transactionGoods="faceRecord.transactionGoods"
    ></points-detailed>
  </div>
  <div @click="onSubmitOrder">
    <TransactionSubmit
      :title="lang.v.confirmOrder"
      :isClickDisabled="faceRecord.isClickDisabled"
    />
  </div>
</template>
<script setup>
import { useRoute, useRouter } from 'vue-router';
import { reactive, onMounted } from 'vue';
import Top from '@/components/Top/top.vue';
import {
  fetchTransactionServeGoods,
  fetchTransactionServeOrder,
  fetchTransactionServeNumber,
} from '@/api/fetcher';
import message from '@/utils/message';
import payment from '@/components/Payment/index.vue';
import dataCenter from '@/dataCenter';
import PointsDetailed from '@/components/PointsDetailed/index.vue';
import TransactionSubmit from '@/components/TransactionSubmit/index.vue';
import TransactionNavbar from '@/components/TransactionNavbar/index.vue';
import { lang } from '@/lang';
const route = useRoute();
const router = useRouter();

const faceRecord = reactive({
  /** 买卖单列表 */
  transactionGoods: {},
  loading: false,
  /** 商品类型 1：买 2：卖 */
  type: 2,
  /** 支付方式 */
  payWay: null,
  // 支付方式名称
  payWayName: '',
  /** 成交单数 */
  dealCount: 0,
  /** 支付备注 */
  payDesc: '',
  /** 禁止提交订单 */
  isClickDisabled: false,
});
// 获取商品信息
const getTransactionGoods = async () => {
  const data = await fetchTransactionServeGoods({
    id: route.query.id,
  });
  faceRecord.transactionGoods = data;
  const foundItem = dataCenter.payStatus.findDataPayStatus(route.query.id);
  if (!foundItem?.payWay) {
    faceRecord.isClickDisabled = true;
  }
};
// 用户成交笔数
const getTransactionServeNumber = async () => {
  const data = await fetchTransactionServeNumber({
    userIds: faceRecord.transactionGoods.creator,
  });
  faceRecord.dealCount = data[faceRecord.transactionGoods.creator];
};
// 提交订单
const onSubmitOrder = async () => {
  let orderPayWay = '';
  const foundItem = dataCenter.payStatus.findDataPayStatus(route.query.id);
  if (foundItem) {
    orderPayWay = foundItem?.payWay;
    faceRecord.payDesc = foundItem.payDesc;
  }
  if (!orderPayWay) return message.warning(lang.v.choosePaymentMethod);
  faceRecord.loading = true;
  const data = await fetchTransactionServeOrder({
    commodityId: route.query.id,
    payWay: orderPayWay,
    payDesc: faceRecord.payDesc,
  }).finally(() => {
    faceRecord.loading = false;
  });
  // 跳转订单页面
  if (data) {
    router.push({
      path: '/transactionServeOrder',
      query: {
        id: route.query?.id,
        orderId: data,
        type: faceRecord?.type,
        payWay: faceRecord?.payWay,
      },
    });
  }
};
onMounted(async () => {
  faceRecord.type = route.query.type;
  await getTransactionGoods();
  getTransactionServeNumber();
});
</script>
<style scoped lang="less">
.main {
  padding: 0.14rem;

  .user {
    display: flex;
    margin-top: 0.17rem;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.12rem;
    height: 0.54rem;
    background: #ffffff;
    border-radius: 0.08rem;
    .user-left {
      display: flex;
      align-items: center;
      .nickname {
        font-weight: 400;
        font-size: 0.14rem;
        color: #000000;
        line-height: 0.16rem;
      }
    }
    .user-right {
      font-weight: 400;
      font-size: 0.12rem;
      color: #858c9f;
      line-height: 0.18rem;
    }
  }
}
.avatar {
  margin-right: 0.05rem;
  width: 0.22rem;
  height: 0.22rem;
}
</style>
